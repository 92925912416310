exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-champions-js": () => import("./../../../src/pages/champions.js" /* webpackChunkName: "component---src-pages-champions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-organization-js": () => import("./../../../src/pages/organization.js" /* webpackChunkName: "component---src-pages-organization-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-sanity-blog-slug-current-js": () => import("./../../../src/pages/{SanityBlog.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-blog-slug-current-js" */),
  "component---src-pages-sanity-next-tournaments-slug-current-js": () => import("./../../../src/pages/{SanityNextTournaments.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-next-tournaments-slug-current-js" */),
  "component---src-pages-sanity-past-tournaments-slug-current-js": () => import("./../../../src/pages/{SanityPastTournaments.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-past-tournaments-slug-current-js" */),
  "component---src-pages-tournaments-js": () => import("./../../../src/pages/tournaments.js" /* webpackChunkName: "component---src-pages-tournaments-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

